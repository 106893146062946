@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(https://fonts.gstatic.com/s/roboto/v19/KFOjCnqEu92Fr1Mu51TjASc6CsE.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfBBc9.ttf) format('truetype');
}
.form-field {
  margin-bottom: 40px;
}
.form-field__message {
  font-size: 14px;
  font-weight: 400;
  margin: 6px 0 0 10px;
  display: inline-block;
}
.form-field__message--error {
  color: #B52700;
}
.form .button-container {
  align-items: center;
  justify-content: flex-start;
}
.form .button-container .button {
  margin-right: 20px;
}
.form .button-container .button.danger {
  background-color: #B52700;
}
.form .button-container a {
  cursor: pointer;
}
.form .button-container a.danger {
  color: #B52700;
}
label {
  display: block;
  color: #505F79;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 700;
  margin-bottom: 5px;
}
label .forgot-password {
  font-weight: normal;
}
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  border: 1px solid #EBECF0;
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  outline: none;
}
input[type='text']:disabled,
input[type='email']:disabled,
input[type='password']:disabled,
textarea:disabled {
  color: #505F79;
  background-color: #EBECF0;
}
input[type='text']:invalid,
input[type='email']:invalid,
input[type='password']:invalid,
textarea:invalid {
  border-color: #B52700;
}
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}
.checkbox__input {
  position: absolute;
  opacity: 0;
}
.checkbox__input:checked ~ .checkbox__checkmark:after {
  display: inline-block;
  left: 8px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid #008F84;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkbox__text {
  display: inline-block;
  margin-top: 4px;
}
.checkbox__text__smallprint {
  font-size: 12px;
}
.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 26px;
  border: 1px solid #EBECF0;
  border-radius: 3px;
  background-color: #ffffff;
}
.checkbox__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.switch-container .label {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.1;
  color: #505F79;
}
.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
}
.switch input {
  display: none;
}
.switch input:checked + .slider {
  background-color: #008F84;
}
.switch input:checked + .slider:before {
  transform: translateX(60px);
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  left: 4px;
  bottom: 5px;
  background-color: #ebecf0;
  transition: 0.4s;
  border-radius: 50%;
}
.url {
  padding-top: 10px;
  font-size: 14px;
}
.url a:hover {
  text-decoration: none;
}
.message-box {
  padding: 25px 20px;
  margin-bottom: 40px;
}
.message-box--error {
  background-color: rgba(181, 39, 0, 0.1);
}
.message-box--success {
  background-color: rgba(0, 143, 132, 0.1);
}
.message-box h4 {
  font-size: 16px;
  margin: 0;
}
.message-box__message {
  font-size: 16px;
  font-weight: 400;
}
hr {
  margin: 40px 0;
}
body {
  font-family: Roboto, sans-serif;
  color: #2B3148;
  font-size: 16px;
  background-color: #F2F6F7;
}
body.with-stroke {
  background: url('../img/body-shape.svg') 35% 35% no-repeat #ffffff;
}
body.no-scroll {
  overflow: hidden;
}
h1 {
  font-size: 32px;
  line-height: 1.4;
  font-weight: 900;
}
@media (min-width: 768px) {
  h1 {
    font-size: 48px;
  }
}
@media (min-width: 962px) {
  h1 {
    font-size: 64px;
  }
}
h1 a {
  color: #2B3148;
}
h1 a:hover {
  text-decoration: none;
}
a {
  color: #2059B8;
  line-height: 19px;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
section {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  section {
    margin-bottom: 150px;
  }
}
.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
}
@media (min-width: 768px) {
  .container {
    padding: 0 60px;
  }
}
@media (min-width: 962px) {
  .container {
    padding: 0 120px;
  }
}
.block {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .block {
    flex-direction: row;
  }
}
.button-container {
  display: flex;
  justify-content: center;
}
.button {
  height: 60px;
  border-radius: 30px;
  background-color: #008F84;
  border: none;
  padding: 0 50px;
  font-weight: 900;
  color: #ffffff;
  font-size: 18px;
  outline: none;
}
.button:disabled {
  background-color: rgba(0, 143, 132, 0.3);
}
@media (min-width: 768px) {
  .button {
    font-size: 24px;
  }
}
.button--small {
  height: 30px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: normal;
}
.button--blue {
  background-color: #2059B8;
}
.button--red {
  background-color: #B52700;
}
.link-button {
  color: #2059B8;
  font-size: 16px;
  line-height: 2;
  border: none;
  background: none;
}
.account {
  border: none;
  background: url('../img/user-ico.svg') center no-repeat #2059B8;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.proceed-button {
  border: none;
  background: url('../img/arrow-down.svg') 16px 0 no-repeat;
  animation: arrow-bottom 1.7s infinite ease;
}
.proceed-button:after {
  content: '';
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-top: 30px;
  background: url('../img/arrow-bg-circle.png') 0 0 no-repeat;
}
@keyframes arrow-bottom {
  0% {
    background-position-y: 0;
  }
  50% {
    background-position-y: 11px;
  }
  55% {
    background-position-y: 11px;
  }
  100% {
    background-position-y: 0;
  }
}
.header {
  background: url('../img/header-bg.png') 100% 0 no-repeat;
  background-size: contain;
}
@media (min-width: 375px) {
  .header {
    min-height: 275px;
  }
}
@media (min-width: 768px) {
  .header {
    background-size: 65%;
    min-height: 450px;
  }
}
@media (min-width: 962px) {
  .header {
    min-height: 650px;
  }
}
@media (min-width: 1440px) {
  .header {
    min-height: 37vw;
    background-size: 55%;
  }
}
.header .proceed-button {
  position: absolute;
  bottom: -85%;
  left: 0;
}
@media (min-width: 375px) {
  .header .proceed-button {
    bottom: -60%;
  }
}
@media (min-width: 768px) {
  .header .proceed-button {
    bottom: -40%;
  }
}
.header-container {
  padding-top: 65%;
  position: relative;
}
@media (min-width: 480px) {
  .header-container {
    padding-top: 40%;
  }
}
@media (min-width: 768px) {
  .header-container {
    padding-top: 170px;
  }
}
@media (min-width: 375px) {
  .header-container h1 {
    width: 60%;
  }
}
@media (min-width: 768px) {
  .header-container h1 {
    width: 40%;
  }
}
.simple-header {
  background-color: #ffffff;
}
.top-nav {
  padding-bottom: 20px;
  border-bottom: 1px solid #F2F6F7;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .top-nav {
    justify-content: space-between;
    flex-direction: row;
  }
}
.top-nav .owner {
  font-size: 18px;
  font-weight: normal;
}
.top-nav .owner a {
  color: #2059B8;
}
.top-nav .user-nav {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}
@media (min-width: 768px) {
  .top-nav .user-nav {
    justify-content: flex-end;
  }
}
.top-nav .user-nav .account {
  margin: 0 40px 0 20px;
}
.footer {
  color: #ffffff;
  background: url("../img/footer-bg.png") 0 100% no-repeat;
  padding: 200px 0 80px;
  background-size: cover;
}
@media (min-width: 768px) {
  .footer {
    background-size: contain;
  }
}
@media (min-width: 1650px) {
  .footer {
    background-position: 0 -40%;
    background-size: 62%;
  }
}
@media (min-width: 1650px) {
  .footer {
    background-position: 0 -18%;
    background-size: 74%;
  }
}
.footer h1 {
  margin-bottom: 80px;
}
.footer .build-by {
  margin-bottom: 20px;
}
.footer .build-by tr td {
  font-size: 18px;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .footer .build-by tr td {
    font-size: 24px;
  }
}
.footer .build-by tr td:first-child {
  font-weight: 900;
  padding-right: 20px;
}
.footer .copyright {
  font-size: 18px;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .footer .copyright {
    font-size: 24px;
  }
}
.about .description {
  font-size: 18px;
  line-height: 1.5;
  max-width: 790px;
  margin: 0 auto 70px;
}
@media (min-width: 768px) {
  .about .description {
    font-size: 24px;
  }
}
.about .block {
  flex-direction: column;
}
@media (min-width: 480px) {
  .about .block {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .about .block {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 80px;
  }
}
.about .block-item {
  position: relative;
  max-width: 375px;
  min-height: 285px;
  margin: 0 auto 40px;
  background: center no-repeat;
  background-size: contain;
  width: 100%;
}
@media (min-width: 768px) {
  .about .block-item {
    width: 35%;
    min-height: 385px;
    margin: 0 1%;
  }
}
.about .block-item span {
  position: absolute;
}
.about .block-item.my-boss {
  background-image: url("../img/boss-img.png");
}
.about .block-item.my-boss .title {
  top: 50px;
  left: 24px;
}
.about .block-item.my-boss .digit {
  top: 100px;
  left: 14px;
}
.about .block-item.myself {
  background-image: url("../img/myself-img.png");
}
.about .block-item.myself .title {
  bottom: 90px;
  left: 24px;
}
.about .block-item.myself .digit {
  bottom: 0;
  left: 24px;
}
.about .block-item.other {
  background-image: url("../img/other-img.png");
}
.about .block-item.other .title {
  top: 52px;
  left: 24px;
  width: 30%;
}
.about .block-item.other .digit {
  top: 152px;
  left: 20px;
}
.about .block .title {
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
  line-height: 36px;
}
.about .block .digit {
  font-size: 48px;
  line-height: 1.4;
  font-weight: 900;
}
@media (min-width: 962px) {
  .about .block .digit {
    font-size: 64px;
  }
}
.about .block .digit:after {
  content: '%';
  font-size: 32px;
}
.how-it-works .description {
  font-size: 18px;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .how-it-works .description {
    margin-left: 17%;
    margin-top: 80px;
    font-size: 24px;
  }
}
.details .block {
  justify-content: space-around;
}
.details .block-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .details .block-item {
    margin-bottom: 0;
  }
}
.details .block-item .name {
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  margin: 0 0 6px;
}
@media (min-width: 768px) {
  .details .block-item .name {
    margin: 40px 0 6px;
  }
}
.is-hidden {
  display: none;
}
.button-close {
  background: url('../img/close-icon.svg') center no-repeat #008F84;
  border-radius: 50%;
  border: none;
  display: inline-block;
  height: 38px;
  width: 38px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  display: none;
}
.overlay.visible {
  display: block;
}
.modal {
  width: 100%;
  max-width: 480px;
  padding: 40px;
  min-height: 300px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f2f6f7;
  z-index: 2;
  display: none;
  overflow-y: auto;
  max-height: calc(80vh - 40px);
}
@media (min-width: 768px) {
  .modal {
    top: 20%;
    padding: 40px 80px;
  }
}
.modal.visible {
  display: block;
}
.modal h1 {
  font-size: 36px;
  margin-top: 0;
}
@media (min-width: 962px) {
  .modal h1 {
    font-size: 44px;
  }
}
.modal > p {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .modal > p {
    font-size: 24px;
  }
}
.modal > p.smallprint {
  font-size: 18px;
}
.modal > p > a {
  white-space: nowrap;
}
.modal ul {
  font-size: 16px;
  line-height: 26px;
  padding-left: 26px;
}
.modal .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 143, 132, 0.05);
  overflow: hidden;
  display: none;
}
.modal .progress.visible {
  display: block;
}
.modal .progress .inner {
  background-color: #008F84;
}
.modal .progress .inner:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.modal .progress .inner:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
.modal .success {
  stroke-dasharray: 130px;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 500ms ease-in-out;
  animation: success 700ms 500ms ease-in-out;
}
.modal .success__text {
  font-weight: 400;
}
@keyframes success {
  0% {
    stroke-dashoffset: 130px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.calendar {
  background-color: #ffffff;
  margin-bottom: 80px;
}
.datepicker {
  padding: 32px 0;
  position: relative;
  display: flex;
  align-items: flex-start;
}
.datepicker__month-toggle {
  color: #2059B8;
  font-size: 15px;
  line-height: 36px;
  text-transform: uppercase;
  background: none;
  border: none;
}
.datepicker__today-toggle {
  color: #2059B8;
  font-size: 15px;
  line-height: 1.2;
  text-transform: uppercase;
  background: none;
  border: none;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}
.datepicker .month {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 2%;
  padding: 0;
}
.datepicker .month .date {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 6px;
  cursor: pointer;
}
.datepicker .month .date.current {
  border: 2px solid rgba(32, 89, 184, 0.5);
  padding: 10px 4px;
}
.datepicker .month .date.current .indicator {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 2px solid #2059B8;
}
.datepicker .month .date.weekend {
  background-color: rgba(80, 95, 121, 0.1);
}
.datepicker .month .date.weekend .digit {
  color: rgba(43, 49, 72, 0.2);
}
.datepicker .month .date .day {
  font-size: 10px;
  font-weight: 400;
  color: rgba(43, 49, 72, 0.5);
}
.datepicker .month .date .digit {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
}
.datepicker .month .date .month-short {
  font-size: 8px;
  color: rgba(43, 49, 72, 0.3);
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.datepicker .month .date .indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #DAE3E5;
}
.datepicker .month .date .indicator.selected {
  width: 16px;
  height: 16px;
  background-color: #2059B8;
  border: 2px solid #2059B8;
}
@media (min-width: 768px) {
  .line-charts,
  .pie-charts {
    margin-bottom: 50px;
  }
}
.line-charts .block,
.pie-charts .block {
  justify-content: space-between;
  align-items: flex-start;
}
.line-charts .block.loading .block-item .c100,
.pie-charts .block.loading .block-item .c100 {
  animation: rotation 3s ease-in-out infinite;
}
.line-charts .block-item,
.pie-charts .block-item {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .line-charts .block-item,
  .pie-charts .block-item {
    width: 30%;
  }
}
.line-charts .block-item .name,
.pie-charts .block-item .name {
  text-align: center;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
  line-height: 1.5;
  margin-top: 30px;
}
.line-charts .block-item .caption,
.pie-charts .block-item .caption {
  color: rgba(43, 49, 72, 0.5);
  font-size: 16px;
  padding-top: 12px;
  line-height: 24px;
}
.line-charts .block .c100:after,
.pie-charts .block .c100:after {
  background-color: #DAE3E5;
}
.line-charts .block .c100:hover .donut-inner,
.pie-charts .block .c100:hover .donut-inner {
  color: inherit;
}
@media (min-width: 768px) {
  .line-charts .block .c100.big,
  .pie-charts .block .c100.big {
    font-size: 180px;
  }
}
@media (min-width: 1280px) {
  .line-charts .block .c100.big,
  .pie-charts .block .c100.big {
    font-size: 340px;
  }
}
.line-charts .c100.yellow .bar,
.pie-charts .c100.yellow .bar,
.line-charts .c100.yellow .fill,
.pie-charts .c100.yellow .fill {
  border-color: #FFAD27 !important;
}
.line-charts .c100.blue .bar,
.pie-charts .c100.blue .bar,
.line-charts .c100.blue .fill,
.pie-charts .c100.blue .fill {
  border-color: #2059B8 !important;
}
.line-charts .c100.light-green .bar,
.pie-charts .c100.light-green .bar,
.line-charts .c100.light-green .fill,
.pie-charts .c100.light-green .fill {
  border-color: #008F84 !important;
}
.line-charts .donut-inner,
.pie-charts .donut-inner {
  font-weight: 700;
  color: #2B3148;
}
.line-charts .donut-inner:after,
.pie-charts .donut-inner:after {
  content: '%';
  font-size: 32px;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
section.tabs {
  margin-bottom: 60px;
}
.tab-list {
  border-bottom: 2px solid #DAE3E5;
  margin: 0;
  padding: 0 0 20px;
}
.tab-header {
  list-style: none;
  color: #2b3148;
  font-weight: 900;
  line-height: 24px;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 12px;
}
.tab-header:last-child {
  margin-right: 0;
}
@media (min-width: 375px) {
  .tab-header {
    margin-right: 30px;
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .tab-header {
    margin-right: 80px;
    font-size: 20px;
  }
}
.tab-header.react-tabs__tab--selected {
  background: none;
  position: relative;
}
.tab-header.react-tabs__tab--selected:after {
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  margin-top: 20px;
  height: 2px;
  background-color: #2059B8;
}
